<template>
    <div id="contactUs">
        <!-- 头部 -->
        <AppHead />
        <div class="contactCont">
            <div class="leftBox">
                <div class="moduleTitle">
                    <span>{{ $t('common.navContactUs') }}</span>
                </div>
                <ul class="contactDetails">
                    <li class="itemDetails">
                        <p class="itemDetailsTitle">{{ $t('contactUs.contactText1') }}</p>
                        <p class="itemDetailsText">bd@newborntown.com</p>
                    </li>
                    <li class="itemDetails">
                        <p class="itemDetailsTitle">{{ $t('contactUs.contactText2') }}</p>
                        <p class="itemDetailsText">pr@newborntown.com</p>
                    </li>
                    <li class="itemDetails">
                        <p class="itemDetailsTitle">{{ $t('contactUs.contactText3') }}</p>
                        <p class="itemDetailsText">ir@newborntown.com</p>
                    </li>
                    <li class="itemDetails">
                        <p class="itemDetailsTitle">{{ $t('contactUs.contactTextAdress') }}</p>
                        <p class="itemDetailsText">{{ $t('contactUs.contactTextHKAdress') }}</p>
                        <p class="itemDetailsText">{{ $t('contactUs.contactTextBJAdress') }}</p>
                    </li>
                </ul>
            </div>
            <div class="rightBox">
                <img class="contactImg" src="../assets/images/contenUs.webp" alt="contenUs image">
            </div>
        </div>
        
        <div class="tipsWrap">
            <div class="tipsContent">
                <p class="tipsTitle">特別提示</p>
                <p class="tipsText">近日，赤子城科技關注到，疑似有不法人員冒用公司名義及偽造公司營業執照等相關證明文件組織「刷單」、「返利」等活動，造成他人經濟損失。</p>
                <p class="tipsText">公司在此鄭重聲明：赤子城科技及旗下附屬公司（以下簡稱「本公司」）從未開展上述相關業務，網傳《CZC》App 非本公司開發、運營，與本公司無任何關係。</p>
                <p class="tipsText">該事件對公司聲譽造成不良影響，公司已就被偽造公文、證件、證明文件、印章向公安機關報案，後續將積極配合相關部門調查。在此提醒各位網友，警惕網絡騙局，如已遭受損失，可儘快向當地公安機關報案，通過法律途徑維護自身合法權益。</p>
                <p class="tipsText right">赤子城科技</p>
                <p class="tipsText right">2024年12月19日</p>
            </div>
        </div>
        
        <div v-show="showTipsDialog" class="tipsDialogMask"></div> 
        <div v-show="showTipsDialog" class="tipsDialog">
            <div class="tipsTop">
                <p class="tipsTitle">特別提示</p>
                <svg class="tipsClose" @click="closeTipsDialog" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M4 4L20 20" stroke="#FFFFFF" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4 20L20 4" stroke="#FFFFFF" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="tipsContent">
                <p class="tipsText">近日，赤子城科技關注到，疑似有不法人員冒用公司名義及偽造公司營業執照等相關證明文件組織「刷單」、「返利」等活動，造成他人經濟損失。</p>
                <p class="tipsText">公司在此鄭重聲明：赤子城科技及旗下附屬公司（以下簡稱「本公司」）從未開展上述相關業務，網傳《CZC》App 非本公司開發、運營，與本公司無任何關係。</p>
                <p class="tipsText">該事件對公司聲譽造成不良影響，公司已就被偽造公文、證件、證明文件、印章向公安機關報案，後續將積極配合相關部門調查。在此提醒各位網友，警惕網絡騙局，如已遭受損失，可儘快向當地公安機關報案，通過法律途徑維護自身合法權益。</p>
                <p class="tipsText right">赤子城科技</p>
                <p class="tipsText right">2024年12月19日</p>
            </div>
        </div>
        <!-- 底部 -->
        <AppFoot />
    </div>
</template>
  
  
<script>
import AppHead from '../components/AppHead.vue'
import AppFoot from '../components/AppFoot.vue'

export default {
    name: 'ContactUs',
    components: {
        AppHead,
        AppFoot
    },
    data() {
        return {
            // 控制弹窗是否显示的状态
            showTipsDialog: false
        };
    },
    methods: {
        // 打开弹窗
        openTipsDialog() {
            this.showTipsDialog = true;
            document.body.style.overflow = 'hidden';  // 禁止滚动
        },
        // 关闭弹窗
        closeTipsDialog() {
            this.showTipsDialog = false;
            document.body.style.overflow = 'auto';  // 恢复滚动
        }
    },
    mounted() {
        // 在组件挂载完成后调用 openTipsDialog 方法
        this.openTipsDialog();
    }
}
</script>

<style lang="less">
    .contactCont{
        max-width: 25.6rem;
        margin: 0 auto;
        padding: 1.27rem 2.27rem 1rem 2.27rem;
        display: flex;
        justify-content: space-between;
        .leftBox{
            width: 50%;
            padding-right: 0.2rem;
            .moduleTitle{
                border-left: 0.15rem solid #FF5500;
                padding: 0 .27rem;
                font-weight: 500;
                font-size: 0.35rem;
                color: #888888;
                line-height: 0.48rem;
                text-align: left;
                font-style: normal;
                display: flex;
                flex-direction: column;
                margin-bottom: 0.85rem;
            }
            .contactDetails{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                .itemDetails{
                    margin-bottom: .64rem;
                    .itemDetailsTitle{
                        font-weight: 600;
                        font-size: 0.29rem;
                        color: #FF5500;
                        line-height: 0.4rem;
                        text-align: left;
                        font-style: normal;
                        margin-bottom: .12rem;
                    }
                    .itemDetailsText{
                        font-weight: 400;
                        font-size: 0.2rem;
                        color: #888888;
                        line-height: 0.43rem;
                        text-align: left;
                        font-style: normal;
                    }
                }
                .itemDetails:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .rightBox{
            width: 50%;
            display: flex;
            align-items: center;
            .contactImg {
                // width: 9.92rem;
                // height: 5.88rem;
                width: 100%;
            }
        }
    }
    .tipsWrap{
        max-width: 25.6rem;
        margin: auto;
        padding: 0 2.27rem 1.77rem 2.27rem;
        .tipsContent{
            padding: 0.2rem 0.5rem;
            border: 0.05rem solid #888888;
            .tipsTitle{
                font-weight: 400;
                font-size: 0.22rem;
                color: #cccccc;
                line-height: 0.44rem;
                padding: 0.1rem 0;
                text-align: center;
            }
            .tipsText{
                font-weight: 400;
                font-size: 0.18rem;
                color: #888888;
                line-height: 0.36rem;
            }
        }
    }

    .tipsDialogMask{
        position: absolute;
        background: #00000060;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100vh;
        opacity: 0.7;
    }

    .right{
        text-align: right !important;
    }

    .tipsDialog{
        max-width: 6rem;
        position: fixed;
        right: 50%;
        bottom: 50%;
        transform: translateX(50%) translateY(50%);
        // right: 0.2rem;
        // bottom: 0.2rem;
        background: #FFFFFF;
        border-bottom: 0.2rem solid #FF5500;
        z-index: 2;
        .tipsTop{
            height: 0.6rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FF5500;
            .tipsTitle{
                flex-shrink: 0;
                font-weight: 400;
                font-size: 0.2rem;
                color: #FFFFFF;
                line-height: 0.4rem;
                padding: 0.1rem 0.2rem;
            }
            .tipsClose{
                flex-shrink: 0;
                width: 0.3rem;
                height: 0.3rem;
                padding: 0.1rem 0.2rem;
                cursor: pointer;
            }
        }
        .tipsContent{
            padding: 0.1rem 0.2rem;
            border-left: 1px solid #FF5500;
            border-right: 1px solid #FF5500;
            .tipsText{
                font-weight: 400;
                font-size: 0.16rem;
                color: #333333;
                line-height: 0.26rem;
                margin-bottom: 0.1rem;
            }
        }
    }
</style>